import React, {useEffect, useRef} from "react";
import { useState } from "react";

import './App.css';
import SearchIcon from './images/search.svg';
import Logo from './images/logo_transparent.png';

import MovieCard from "./MovieCard";

const apiKey = 'b2224022';
const API_URL = `https://www.omdbapi.com?apikey=${apiKey}`;

const App = () => {
    const [movies, setMovies] = useState([]);
    const [response, setResponse] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    let loaded = useRef(false);
    const searchMovies = async (title) => {
        const response = await fetch(`${API_URL}&s=${title}`);
        const data = await response.json();
        setMovies(data.Search);
        setResponse(data.Response);
    }
    useEffect(()=>{
        if(loaded.current && searchTerm.length>2){
            const timer = setTimeout(()=> {
                searchMovies(searchTerm);
            }, 500);
            return () => {
                clearTimeout(timer)
            }
        }else {
            loaded.current = true;
        }
    }, [searchTerm])
    return (
        <div className="app">
            <div className='header'>
                <a href="https://zedpro.me">
                    <img className='logo' alt="logo" src={Logo}/>
                </a>
                <a href="https://zedpro.me">
                    <h1>zMDB</h1>
                </a>
            </div>
            <div className="search">
                <input
                    placeholder='Search for movies'
                    value={searchTerm}
                    onChange={e => {
                        setSearchTerm(e.target.value);
                    }}
                    onKeyUp={e => {
                        if(e.key === 'Enter'){
                            searchMovies(searchTerm);
                        }
                    }}
                />
                <img
                    src={SearchIcon}
                    alt="Search"
                    onClick={() => searchMovies(searchTerm)}
                />
            </div>
            {
                movies?.length>0
                ? (
                    <div className="container">
                        {movies.map(movie => (
                            <MovieCard key={movie.imdbID} movie={movie}/>
                        ))}
                    </div>
                ) :
                (
                    response==='False'?(
                            <div className="empty">
                                <h2>No movies found</h2>
                            </div>
                    ) : (
                        searchTerm?.length>0?(
                            <div className="empty">
                                <h2>Click the search icon or press the 'Enter' key to complete the search</h2>
                            </div>
                        ) : (
                                <div className="empty">
                                    <h2>Please enter a movie title into the search bar</h2>
                                </div>
                            )
                    )
                )
            };
        </div>
    );
};
export default App;